import { FileInfo } from '../components/shared/file-upload/FileInfo';
import { v4 as uuidv4 } from 'uuid';
import FileService from '../services/FileService';

const units = ['b', 'kb', 'mb', 'gb'];

export class FileUtils {
  // https://stackoverflow.com/a/18650828
  static formatSize(bytes: number): string {
    if (bytes === 0) {
      return '0 b';
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(1)) + ' ' + units[i];
  }

  static removeDuplicates(files: FileInfo[]): FileInfo[] {
    return files.filter((file, index, arr) => arr.findIndex((f) => f.id === file.id) === index);
  }

  static mapFileInfo(files: File[]): FileInfo[] {
    return files.map((file) => {
      return this.toFileInfo(file);
    });
  }

  static toFileInfo(file: File): FileInfo {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { id: (file as any).id || uuidv4(), name: file.name, type: file.type, size: file.size, mimeType: file.type, file };
  }

  static download(url: string, fileName = ''): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  static downloadFile({ id, name, addExtension }: { id: string; name: string; addExtension?: boolean }) {
    FileService.getFile(id).then((fileBlob) => {
      const url = URL.createObjectURL(fileBlob);
      const extension = this.getFileExtensionFromMimeType(fileBlob.type);
      const fileName = addExtension && !name.endsWith(extension) ? `${name}.${extension}` : name;
      this.download(url, fileName);
    });
  }

  static getFileUrl(fileId: string): Promise<string> {
    return FileService.getFile(fileId).then((fileBlob) => {
      const url = URL.createObjectURL(fileBlob);
      return url;
    });
  }

  static getFileNameFromPath(filePath: string) {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  }

  static getFileExtensionFromMimeType(mimeType: string) {
    if (!mimeType) {
      return '';
    }

    const mimeToExtensionMap: { [key: string]: string } = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      // Add more mappings as needed
    };

    const lowerCaseMimeType = mimeType.toLowerCase();

    if (mimeToExtensionMap[lowerCaseMimeType]) {
      return mimeToExtensionMap[lowerCaseMimeType];
    } else {
      // If not found in the mapping, attempt to extract it
      // from the MIME type itself (not recommended for all types)
      const ext = mimeType.split('/').pop();
      return ext ? ext : '';
    }
  }
}
